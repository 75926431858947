import React from 'react';

interface IHeader {
  name: string;
}

export const Header = (props: IHeader): JSX.Element => {
  return (
    <div className="top-bar">
      <div className="top-bar-left">
        <ul className="menu">
          <li className="menu-text">{props.name}</li>
        </ul>
      </div>
      <div className="top-bar-right">
        {/* <ul className="menu">
          <li>
            <a href="/">One</a>
          </li>
          <li>
            <a href="/">Two</a>
          </li>
          <li>
            <a href="/">Three</a>
          </li>
          <li>
            <a href="/">Four</a>
          </li>
        </ul> */}
      </div>
    </div>
  );
};
