import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './foundation.min.css';
import { Header } from './header';
import image from './images/coming_soon.png';

class App extends React.Component {
  render(): JSX.Element {
    const uri = `${process.env.REACT_APP_BACKEND_BASE_URI}/HttpTest`;
    return (
      <div>
        <Header name="RumorSource" />
        <img src={image} alt="comming soon" />
        <button
          onClick={() => {
            fetch(uri)
              .then((response) => response.text())
              .then((text) => alert(text));
          }}
        >
          Press me to call {uri}
        </button>
      </div>
    );
  }
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
